<template>
    <div class="equipment-form">
    
        <div class="form-content-box">
        
            <div class="form-container">
                <div class="form-body">

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>所属分类</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <span class="category-title">{{ category.title }}</span>
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>设备名称</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-input placeholder="请输入设备名称" v-model:value="formData.name" />
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>设备类别</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-select 
                                :value="currentType.label" 
                                :options="types"
                                placeholder="请选择设备类别" 
                                @choose="chooseType"
                            />
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>品牌</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.brand" placeholder="请输入品牌名称" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>产地</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.origin" placeholder="请输入设备产地" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>认证</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.certification" placeholder="请输入设备认证" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>新旧程度</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.condition" placeholder="请输入新旧程度" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>保修期</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.warranty" placeholder="请输入保修时间" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>售后服务</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-textarea 
                                v-model:value="formData.after_sale_service" 
                                placeholder="请输入售后服务" 
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>尺寸</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.dimension" placeholder="请输入设备尺寸" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>重量</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.weight" placeholder="请输入设备重量" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>容量</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.capacity" placeholder="请输入设备容量" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>材质</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.meterial" placeholder="请输入设备材质" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>内抛光精度</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.inner_polishing_precision" placeholder="请输入内抛光精度" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>加工类型</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.processing_type" placeholder="请输入加工类型" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>加工</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.processing" placeholder="请输入加工" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>适用行业</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-textarea v-model:value="formData.applicable_industries" placeholder="请输入适用行业" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>起订量</span>
                                <!-- <em>*</em> -->
                            </div>
                        </div>
                        <div class="item">
                            <n-input v-model:value="formData.moq" placeholder="请输入最小起订量" />
                        </div>
                    </div>

                    <!-- 啤酒酿造设备 -->
                    <template v-if="currentType.value === 1">
                         <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>加热方式</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备加热方式" v-model:value="formData.heating_method" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>功率</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备功率" v-model:value="formData.power" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>电压</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备电压" v-model:value="formData.voltage" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>功能</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备功能" v-model:value="formData.function" />
                            </div>
                        </div>
                    </template>

                    <!-- 发酵罐 -->
                    <template v-else-if="currentType.value === 2">
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>功能</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备功能" v-model:value="formData.function" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>厚度</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备厚度" v-model:value="formData.thickness" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>焊接</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备焊接" v-model:value="formData.welding" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>工艺</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备工艺" v-model:value="formData.process" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>绝缘</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备绝缘" v-model:value="formData.insulation" />
                            </div>
                        </div>
                    </template>

                    <!-- 灌装线 -->
                    <template v-else-if="currentType.value === 4">
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>操作方式</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.operation_type" placeholder="请输入设备操作方式" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>类型</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.type" placeholder="请输入类型" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>灌装液</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.filling_liquid" placeholder="请输入灌装液" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>电压</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备电压" v-model:value="formData.voltage" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>功能</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.function" placeholder="请输入设备功能" />
                            </div>
                        </div>
                    </template>

                    <!--  -->
                    <template v-else-if="currentType.value === 5">
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>型号编号</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.model_number" placeholder="请输入型号编号" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>类型</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.type" placeholder="请输入类型" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>用法</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.usage" placeholder="请输入用法" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>类型ADSG</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.type_of_spear" placeholder="请输入类型ADSG" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>包装</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.package" placeholder="请输入包装方式" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>标志</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.sign" placeholder="请输入设备标志" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>表面处理</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.surface_treatment" placeholder="请输入设备表面处理" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>颈部焊接</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.neck_soldering" placeholder="请输入颈部焊接工艺" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>存储</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.storage" placeholder="请输入存储方式" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>自定义</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-switch v-model:value="formData.custom" />
                            </div>
                        </div>
                    </template>

                    <template v-else-if="currentType.value === 6">
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>功能</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input placeholder="请输入设备功能" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                <div class="label-container">
                                    <span>包装方法</span>
                                    <!-- <em>*</em> -->
                                </div>
                            </div>
                            <div class="item">
                                <n-input v-model:value="formData.packing_method" placeholder="请输入包装方法" />
                            </div>
                        </div>
                    </template>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>关键字</span>
                            </div>
                        </div>
                        <div class="item keywords">
                            <n-tag 
                                class="tag"
                                theme="fill" type="primary"
                                v-for="(item, index) in formData.keywords" :key="index"
                                @close="removeKeyword(index)"
                            >
                                {{ item }}
                            </n-tag>
                            <n-input
                                class="keyword-input"
                                v-model:value="keyword"
                                @enter="addKeyword"
                                placeholder="输入关键字"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>描述</span>
                            </div>
                        </div>
                        <div class="item">
                            <n-textarea 
                                v-model:value="formData.description"
                                :maxlength="140"
                            ></n-textarea>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-container form-editor">
                <n-editor class="form-content" v-model:data="formData.content" />
            </div>

        </div>

        <div class="form-slider">
            <div class="item">
                <h5>作者</h5>
                <div class="info author">
                    <n-avatar :size="40" />
                    <div class="name-box">
                        <span class="name">{{ $store.state.userInfo.name }}</span>
                        <span class="phone">{{ $store.state.userInfo.phone }}</span>
                    </div>
                </div>
            </div>


            <div class="item">
                <h5>发表状态</h5>
                <div class="info">
                    <n-select 
                        :zIndex="9999"
                        v-model:value="formData.status.label" 
                        :options="statuses" 
                        placeholder="请选择状态"
                        @choose="checkStatus"
                    />
                </div>
            </div>
            <div class="item">
                <h5>访问权限</h5>
                <div class="info">
                    <n-select 
                        :zIndex="9999"
                        v-model:value="formData.permissions.label" 
                        placeholder="请选择文章访问权限"
                    >
                        <template v-for="(item, index) in permissionses" :key="index">
                            <n-select-option 
                                :label="item.label" :index="index"
                                :checked="formData.permissions.label === item.label"
                                @choose="checkPermission"
                            />
                        </template>
                    </n-select>
                </div>
            </div>
            <div class="item">
                <h5>发表时间</h5>
                <div class="info published">
                    <n-date-picker class="one" :date="formData.published_at.date" @change="dateChange" />
                    <n-time-picker class="one" :time="formData.published_at.time" position="bottom right" @change="timeChange" />
                </div>
            </div>
            <div class="item">
                <h5>缩略图</h5>
                <div class="info thumb">
                    <n-upload v-model:src="formData.thumb" width="auto" :height="150" />
                </div>
            </div>
        </div>

    </div>

    <div class="bottom-tools-box">
        <n-button class="tool-btn" size="large" @click="reset">重置</n-button>
        <n-button class="tool-btn" 
            size="large" type="primary" 
            :disabled="submitDisabled"
            :loading="btnLoading"
            @click="submit"
        >确定</n-button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NEditor from '@/components/layout/Editor.vue';

@Options({
    components: {
        NEditor
    },
    props: {
        category: {
            type: Object,
            default: {
                id: '',
                title: '',
                type: ''
            }
        },
        form: {
            type: Object,
            default: {}
        },
        type: {
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData: {
                cat_id: '',
                name: '',
                type_id: '', // 类型id
                brand: '',  // 品牌
                origin: '', // 产地
                certification: '', // 认证
                condition: '', //新旧
                warranty: '', // 保修期
                after_sale_service: '', // 售后服务
                dimension: '', //尺寸
                weight: '',    // 重量
                capacity: '', // 容量
                meterial: '', //材质
                inner_polishing_precision: '', // 内抛光精度
                processing_type: '', // 加工类型
                processing: '', //加工
                applicable_industries: '', //适用行业
                moq: 1, // 最小起订量

                keywords: [],
                description: '',
                content: '',
                status: {
                    label: '发布',
                    value: 'published'
                },
                permissions: {
                    label: '公开',
                    value: 'public'
                },
                published_at: {
                    date: {},
                    time: {}
                },


                // type === 1
                heating_method: '', // 加热方式
                power: '', // 功率
                voltage: '', // 电压


                function: '', // 功能

                // type === 2
                thickness: '', // 厚度
                welding: '', // 焊接
                process: '', // 工艺
                insulation: '',  // 绝缘

                // type === 4
                operation_type: '', //操作方式
                type: '', //瓶式/桶式/罐式（）：玻璃瓶/啤酒桶/铝罐
                filling_liquid: '', // 灌装液

                // type === 5
                model_number: '', //型号编号
                usage: '', // 用法
                type_of_spear: '', // 类型 A/D/S/G
                package: '', // 包装
                sign: '', //标志：浮雕、雕刻、丝印
                surface_treatment: '', // 表面处理
                neck_soldering: '', // 颈部焊接
                storage: '', // 存储：堆栈
                custom: false, // 是否支持自定义

                // type === 6
                packing_method: '', // 包装方法
                
            },
            keyword: '',
            statuses: [
                {
                    label: '发布',
                    value: 'published'
                },{
                    label: '草稿',
                    value: 'draft'
                },{
                    label: '回收站',
                    value: 'deleted'
                }
            ],
            permissionses: [
                {
                    label: '私有',
                    value: 'private'
                },{
                    label: '公开',
                    value: 'public'
                }
            ],
            types: [
                {
                    label: '啤酒酿造设备',
                    value: 1
                },{
                    label: '发酵罐',
                    value: 2
                },{
                    label: '定制不锈钢水箱',
                    value: 3
                },{
                    label: '灌装线',
                    value: 4
                },{
                    label: '啤酒桶',
                    value: 5
                },{
                    label: '其他配件',
                    value: 6
                }
            ],
            currentType: {},
            submitDisabled: true
        }
    },
    watch: {
        'formData':{
            handler(){
                this.formData.cat_id = this.$route.params.id;
                this.submitDisabled = true;
                const data = this.formData;
                if(data.name === '' || data.content === '' || data.type_id === '') this.submitDisabled = true;
                else this.submitDisabled = false;
                // if( data.name === '' || data.brand === '' || data.origin === '' || data.certification === '' || data.condition === '' || data.warranty === '' || data.after_sale_service === '' || data.dimension === '' || data.weight === '' || data.capacity === '' || data.meterial === '' || data.inner_polishing_precision === '' || data.processing_type === '' || data.processing === '' || data.applicable_industries === '' || data.moq === '' || data.moq === 0 || data.content === '' || data.type_id === ''){
                //     this.submitDisabled = true; 
                // } else {
                //     if(Number(data.type_id) === 1){
                //         if(data.heating_method === '' || data.power === '' || data.voltage === '' || data.function === '') this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 2){
                //         if(data.function === '' || data.thickness === '' || data.welding === '' || data.process === '' || data.insulation === '') this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 4){
                //         if(data.operation_type === '' || data.type === '' || data.filling_liquid === '' || data.voltage === '' || data.function) this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 5){
                //         if(data.model_number === '' || data.type === '' || data.usage === '' || data.type_of_spear === '' || data.package === '' || data.sign === '' || data.surface_treatment === '' || data.neck_soldering === '' || data.storage === '' || data.custom === '') this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 6){
                //         if(data.function === '' || data.packing_method === '') this.submitDisabled = true;
                //         else  this.submitDisabled = false;
                //     }
                // }
                
            },
            deep: true
        },
        'form': {
            handler(){
                this.formData = this.form;
            },
            deep: true
        },
        'type': {
            handler(){
                this.currentType = this.type;
            },
            deep: true
        }
    },
    emits: ['submit'],
    inject: ['reload'],
    mounted(){
        this.$api.getUserInfo().then((res: any) => {
            this.$store.commit('changeUserInfo', res.data);
            this.formData.author = res.data.name;
        });
    },
    methods: {
        chooseType(e: any){
            this.currentType = e;
            this.formData.type_id = e.value;
        },
        addKeyword(){
            this.formData.keywords.push(this.keyword);
            this.keyword = ''
        },
        removeKeyword(index: number){
            this.formData.keywords.splice(index, 1);
        },
        checkStatus(e: any){
            this.formData.status = e;
        },
        checkPermission(index: number){
            this.formData.permissions = this.permissionses[index]
        },
        dateChange(e: any){
            this.formData.published_at.date = e;
        },
        timeChange(e: any){
            this.formData.published_at.time = e;
        },
        reset(){
            location.reload();
        },
        submit(){
            this.formData.author = this.$store.state.userInfo.name;
            this.$emit('submit', this.formData);
        }
    }
})

export default class EquipmentForm extends Vue {}
</script>

<style lang="scss" scoped>
@import 'form-style';
.equipment-form{
    @extend .flex-row-between;
    width: 100%;
    .form-content-box{
        width: 74%;
        .form-container{
            margin: 0;
            &.form-editor{
                margin-top: 10px;
            }
        }
    }
}
</style>