
import { Options, Vue } from 'vue-class-component';
import NEditor from '@/components/layout/Editor.vue';

@Options({
    components: {
        NEditor
    },
    props: {
        category: {
            type: Object,
            default: {
                id: '',
                title: '',
                type: ''
            }
        },
        form: {
            type: Object,
            default: {}
        },
        type: {
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData: {
                cat_id: '',
                name: '',
                type_id: '', // 类型id
                brand: '',  // 品牌
                origin: '', // 产地
                certification: '', // 认证
                condition: '', //新旧
                warranty: '', // 保修期
                after_sale_service: '', // 售后服务
                dimension: '', //尺寸
                weight: '',    // 重量
                capacity: '', // 容量
                meterial: '', //材质
                inner_polishing_precision: '', // 内抛光精度
                processing_type: '', // 加工类型
                processing: '', //加工
                applicable_industries: '', //适用行业
                moq: 1, // 最小起订量

                keywords: [],
                description: '',
                content: '',
                status: {
                    label: '发布',
                    value: 'published'
                },
                permissions: {
                    label: '公开',
                    value: 'public'
                },
                published_at: {
                    date: {},
                    time: {}
                },


                // type === 1
                heating_method: '', // 加热方式
                power: '', // 功率
                voltage: '', // 电压


                function: '', // 功能

                // type === 2
                thickness: '', // 厚度
                welding: '', // 焊接
                process: '', // 工艺
                insulation: '',  // 绝缘

                // type === 4
                operation_type: '', //操作方式
                type: '', //瓶式/桶式/罐式（）：玻璃瓶/啤酒桶/铝罐
                filling_liquid: '', // 灌装液

                // type === 5
                model_number: '', //型号编号
                usage: '', // 用法
                type_of_spear: '', // 类型 A/D/S/G
                package: '', // 包装
                sign: '', //标志：浮雕、雕刻、丝印
                surface_treatment: '', // 表面处理
                neck_soldering: '', // 颈部焊接
                storage: '', // 存储：堆栈
                custom: false, // 是否支持自定义

                // type === 6
                packing_method: '', // 包装方法
                
            },
            keyword: '',
            statuses: [
                {
                    label: '发布',
                    value: 'published'
                },{
                    label: '草稿',
                    value: 'draft'
                },{
                    label: '回收站',
                    value: 'deleted'
                }
            ],
            permissionses: [
                {
                    label: '私有',
                    value: 'private'
                },{
                    label: '公开',
                    value: 'public'
                }
            ],
            types: [
                {
                    label: '啤酒酿造设备',
                    value: 1
                },{
                    label: '发酵罐',
                    value: 2
                },{
                    label: '定制不锈钢水箱',
                    value: 3
                },{
                    label: '灌装线',
                    value: 4
                },{
                    label: '啤酒桶',
                    value: 5
                },{
                    label: '其他配件',
                    value: 6
                }
            ],
            currentType: {},
            submitDisabled: true
        }
    },
    watch: {
        'formData':{
            handler(){
                this.formData.cat_id = this.$route.params.id;
                this.submitDisabled = true;
                const data = this.formData;
                if(data.name === '' || data.content === '' || data.type_id === '') this.submitDisabled = true;
                else this.submitDisabled = false;
                // if( data.name === '' || data.brand === '' || data.origin === '' || data.certification === '' || data.condition === '' || data.warranty === '' || data.after_sale_service === '' || data.dimension === '' || data.weight === '' || data.capacity === '' || data.meterial === '' || data.inner_polishing_precision === '' || data.processing_type === '' || data.processing === '' || data.applicable_industries === '' || data.moq === '' || data.moq === 0 || data.content === '' || data.type_id === ''){
                //     this.submitDisabled = true; 
                // } else {
                //     if(Number(data.type_id) === 1){
                //         if(data.heating_method === '' || data.power === '' || data.voltage === '' || data.function === '') this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 2){
                //         if(data.function === '' || data.thickness === '' || data.welding === '' || data.process === '' || data.insulation === '') this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 4){
                //         if(data.operation_type === '' || data.type === '' || data.filling_liquid === '' || data.voltage === '' || data.function) this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 5){
                //         if(data.model_number === '' || data.type === '' || data.usage === '' || data.type_of_spear === '' || data.package === '' || data.sign === '' || data.surface_treatment === '' || data.neck_soldering === '' || data.storage === '' || data.custom === '') this.submitDisabled = true;
                //         else this.submitDisabled = false;
                //     }

                //     if(Number(data.type_id) === 6){
                //         if(data.function === '' || data.packing_method === '') this.submitDisabled = true;
                //         else  this.submitDisabled = false;
                //     }
                // }
                
            },
            deep: true
        },
        'form': {
            handler(){
                this.formData = this.form;
            },
            deep: true
        },
        'type': {
            handler(){
                this.currentType = this.type;
            },
            deep: true
        }
    },
    emits: ['submit'],
    inject: ['reload'],
    mounted(){
        this.$api.getUserInfo().then((res: any) => {
            this.$store.commit('changeUserInfo', res.data);
            this.formData.author = res.data.name;
        });
    },
    methods: {
        chooseType(e: any){
            this.currentType = e;
            this.formData.type_id = e.value;
        },
        addKeyword(){
            this.formData.keywords.push(this.keyword);
            this.keyword = ''
        },
        removeKeyword(index: number){
            this.formData.keywords.splice(index, 1);
        },
        checkStatus(e: any){
            this.formData.status = e;
        },
        checkPermission(index: number){
            this.formData.permissions = this.permissionses[index]
        },
        dateChange(e: any){
            this.formData.published_at.date = e;
        },
        timeChange(e: any){
            this.formData.published_at.time = e;
        },
        reset(){
            location.reload();
        },
        submit(){
            this.formData.author = this.$store.state.userInfo.name;
            this.$emit('submit', this.formData);
        }
    }
})

export default class EquipmentForm extends Vue {}
